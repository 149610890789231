import { HexColorPicker } from 'react-colorful';

import { TVStyleProps } from '../../../style-system';

import { colorPickerStyles } from './color-picker.styles';

type ColorPickerRootProps = {
    name: string;
    required?: boolean;
    defaultValue?: string;
    value?: string;
    disabled?: boolean;
    debounce?: number;
    onChange?: (val: string) => void;
};

type ColorPickerProps = {
    color: string;
    onChange: (val: string) => void;
} & TVStyleProps<typeof colorPickerStyles>;

const ColorPicker = ({ color, onChange }: ColorPickerProps) => {
    return <HexColorPicker color={color} onChange={onChange} />;
};

export { ColorPicker };
export type { ColorPickerProps, ColorPickerRootProps };
