import { forwardRef } from 'react';
import { useController } from 'react-hook-form';

import { useMergeRefs } from '../../../hooks';
import { CombineRefWithProps } from '../../../models';
import { callAllHandlers, cn } from '../../../utils';
import { Checkbox, CheckboxProps } from '../checkbox/checkbox';
import { Field } from '../field';
import type { FieldOrientationOptions } from '../field/context/field-context.model';

import type { FieldValues, FormFieldWithControllerProps } from './form.model';

type FormCheckboxProps<T extends FieldValues> = FormFieldWithControllerProps<T, CheckboxProps, 'orientation'> &
    FieldOrientationOptions<true, false, true>;

export declare function FormCheckboxComponent<T extends FieldValues>(
    props: CombineRefWithProps<HTMLInputElement, FormCheckboxProps<T>>,
): JSX.Element;

const FormCheckbox = forwardRef<HTMLInputElement, FormCheckboxProps<any>>(function FormCheckbox(
    {
        name,
        onChange,
        onBlur,
        control,
        hideErrorMessage,
        helpText,
        disabled,
        readOnly,
        required,
        defaultValue,
        label,
        fullWidth = true,
        orientation = { reverse: false },
        size,
        ...rest
    },
    ref,
) {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required },
        disabled,
        defaultValue,
    });
    const internalRef = useMergeRefs<HTMLInputElement>(field.ref, ref);

    return (
        <Field
            invalid={!!error?.message}
            required={required}
            readOnly={readOnly}
            fullWidth={fullWidth}
            size={size}
            orientation={{ reverse: orientation?.reverse, direction: 'horizontal' }}
        >
            <Field.Orientation className="items-center h-8">
                <Checkbox
                    {...rest}
                    disabled={disabled}
                    name={field.name}
                    ref={internalRef}
                    onChange={callAllHandlers(field.onChange, onChange)}
                    onBlur={callAllHandlers(field.onBlur, onBlur)}
                    checked={field.value}
                />
                <Field.Label classNames={{ label: cn('font-normal', { 'opacity-55': disabled }) }}>{label}</Field.Label>
            </Field.Orientation>
            {hideErrorMessage ? null : <Field.ErrorMessage>{error?.message}</Field.ErrorMessage>}
            {helpText ? <Field.HelpText className="-mt-1 pl-7">{helpText}</Field.HelpText> : null}
        </Field>
    );
}) as unknown as typeof FormCheckboxComponent;

export type { FormCheckboxProps };
export { FormCheckbox };
