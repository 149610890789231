import { forwardRef } from 'react';

import { useControllableState } from '@blockworks/platform/hooks';

import { PropsOf, TVStyleProps } from '../../../style-system';
import { callWith, safeAttr } from '../../../utils';
import { FieldOptions, useFieldProps } from '../field/context';

import { Radio } from './radio';
import { radioStyles } from './radio.styles';
import { radioGroupStyles } from './radio-group.styles';

type RadioGroupProps = {
    options: {
        value: string;
        label: string;
        disabled?: boolean;
        readOnly?: boolean;
    }[];
    defaultValue?: string;
    value?: string;
    onChange?: PropsOf<'input'>['onChange'];
    onValueChange?: (value: string) => void;
    name: string;
    size?: TVStyleProps<typeof radioStyles>['size'];
    id?: string;
} & TVStyleProps<typeof radioGroupStyles> &
    FieldOptions<true>;

const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(function RadioGroup(
    {
        name,
        value: valueProp,
        options,
        size: sizeProp = 'md',
        onValueChange,
        defaultValue,
        orientation: orientationProp,
        ...rest
    },
    ref,
) {
    const {
        fieldProps,
        rootProps,
        orientation,
        size = sizeProp,
    } = useFieldProps<HTMLDivElement>(
        {
            ...rest,
            orientation: { controlDirection: orientationProp },
        },
        {
            includeOrientationRootProp: true,
        },
    );
    const [value, setValue] = useControllableState({
        controlledValue: valueProp,
        defaultValue,
        onChange: onValueChange,
    });

    return (
        <div
            ref={ref}
            className={radioGroupStyles({ orientation: orientation.controlDirection, size })}
            role="radiogroup"
            {...rootProps}
            {...safeAttr(!!value, value, { suffix: 'checked' })}
        >
            {options.map(opt => (
                <Radio
                    key={opt.value}
                    value={opt.value}
                    size={size}
                    name={name}
                    {...fieldProps}
                    disabled={opt.disabled ?? fieldProps?.disabled}
                    readOnly={opt.readOnly ?? fieldProps?.readOnly}
                    checked={value === opt.value}
                    onValueChange={callWith(setValue, opt.value)}
                >
                    {opt.label}
                </Radio>
            ))}
        </div>
    );
});

export type { RadioGroupProps };
export { RadioGroup };
