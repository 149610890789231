import { useController } from 'react-hook-form';

import { useDebounceCallback } from '@blockworks/platform/hooks';

import { CombineRefWithProps } from '../../../models';
import { ColorPicker, ColorPickerRootProps } from '../color-picker';
import { Field } from '../field';
import { FieldOrientationOptions } from '../field/context';

import { FormFieldWithControllerProps } from './form.model';

type FormColorPickerProps = FormFieldWithControllerProps<any, ColorPickerRootProps> & FieldOrientationOptions<true>;

export declare function FormColorPickerComponent(
    props: CombineRefWithProps<HTMLButtonElement, FormColorPickerProps>,
): JSX.Element;

export const FormColorPicker = (props: FormColorPickerProps) => {
    const {
        name,
        label,
        debounce = 200,
        errorMessage,
        hideErrorMessage = false,
        helpText,
        fullWidth,
        control,
        required,
        disabled,
        defaultValue,
    } = props;

    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required },
        disabled,
        defaultValue,
    });

    const debouncedOnChange = useDebounceCallback(field.onChange, debounce);

    return (
        <Field size={'md'} invalid={!!error?.message} fullWidth={fullWidth} required={required} id={name}>
            <Field.Orientation>
                {label ? <Field.Label>{label}</Field.Label> : null}
                <ColorPicker color={field.value ?? defaultValue} onChange={debouncedOnChange} />
            </Field.Orientation>
            {hideErrorMessage ? null : <Field.ErrorMessage>{errorMessage ?? error?.message}</Field.ErrorMessage>}
            {helpText ? <Field.HelpText>{helpText}</Field.HelpText> : null}
        </Field>
    );
};
