import ReactSelect, {
    ActionMeta,
    ClearIndicatorProps,
    components,
    DropdownIndicatorProps,
    MultiValue,
} from 'react-select';

import { ChevronDownIcon, XCloseIcon } from '../../icon';
import { SimpleFieldOptions } from '../field/context';

import { actionStyles, controlStyles, inputStyles, overrideStyles } from './multi-select.styles';

type MultiSelectItem = {
    value: string;
    label: string;
    color?: string;
};

type MultiSelectRootProps = {
    name: string;
    required?: boolean;
    defaultValue?: MultiValue<unknown>[];
    value?: MultiValue<unknown>[];
    disabled?: boolean;
    onChange?: (newValue: MultiValue<any>, actionMeta?: ActionMeta<any>) => void;
};

type MultiSelectProps = {
    options: MultiSelectItem[];
    placeholder?: string;
    className?: string;
    classNamePrefix?: string;
} & MultiSelectRootProps &
    SimpleFieldOptions;

const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
    return (
        <components.DropdownIndicator {...props} className={actionStyles.base}>
            <ChevronDownIcon color="interact" size={1} />
        </components.DropdownIndicator>
    );
};

const ClearIndicator = (props: ClearIndicatorProps<any, true>) => {
    return (
        <components.ClearIndicator {...props} className={actionStyles.base}>
            <XCloseIcon color="interact" size={1} />
        </components.ClearIndicator>
    );
};

const MultiSelect = ({
    options,
    defaultValue,
    placeholder,
    name,
    className,
    classNamePrefix,
    value,
    onChange,
}: MultiSelectProps) => {
    return (
        <ReactSelect
            options={options}
            defaultValue={defaultValue}
            placeholder={placeholder}
            isMulti
            name={name}
            value={value}
            onChange={onChange}
            menuPortalTarget={document.body}
            menuPlacement="bottom"
            menuPosition="absolute"
            styles={overrideStyles}
            classNames={{
                input: () => inputStyles.base,
                control: () => controlStyles.base,
            }}
            components={{
                DropdownIndicator,
                ClearIndicator,
            }}
            className={className}
            classNamePrefix={classNamePrefix}
        />
    );
};

export { MultiSelect };
export type { MultiSelectItem, MultiSelectProps, MultiSelectRootProps, MultiValue };
