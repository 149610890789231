import { forwardRef, useCallback } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { PropsOf } from '../../../style-system';

import { FormCheckbox } from './form.checkbox';
import { FormColorPicker } from './form.color-picker';
import { FormCreatableCombobox } from './form.creatable-combobox';
import { FormEditableText } from './form.editable-text';
import { FormInput } from './form.input';
import { FormMultiSelect } from './form.multi-select';
import { FormRadioGroup } from './form.radio-group';
import { FormSelect } from './form.select';
import { FormSubmit } from './form.submit';
import { FormSwitch } from './form.switch';
import { FormTextArea } from './form.textarea';

type FormProps = Omit<PropsOf<'form'>, 'onSubmit'> & {
    /**
     * The form's `onSubmit` callback event handler.
     * It is thinly wrapped to ensure `e.preventDefault()` is called.
     **/
    onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
    /**
     * Passing `true` will disable the default behavior of the `onSubmit` callback,
     * and skip calling `e.preventDefault`.
     *
     * @default false
     */
    disablePreventDefaultOnSubmit?: boolean;
};

export interface FormNamespace extends React.ForwardRefExoticComponent<FormProps> {
    /** A wrapped `Field` with an internally-controlled `Checkbox` */
    Checkbox: typeof FormCheckbox;
    /** A wrapped `Field` with an internally-controlled `Input` */
    Input: typeof FormInput;
    /**
     * A searchable select component, with the ability to create custom value
     * that doesn't exist in the `options` array.
     */
    CreatableCombobox: typeof FormCreatableCombobox;
    /** A wrapped `Field` with an internally-controlled `Select` */
    Select: typeof FormSelect;
    MultiSelect: typeof FormMultiSelect;
    ColorPicker: typeof FormColorPicker;
    /** A wrapped `Button` that is of `type="submit"` and `variant="primary"` */
    Submit: typeof FormSubmit;
    /** A wrapped `Field` with an internally-controlled `TextArea` */
    TextArea: typeof FormTextArea;
    EditableText: typeof FormEditableText;
    Switch: typeof FormSwitch;
    RadioGroup: typeof FormRadioGroup;
    resolvers: {
        /** A simple drop and replace of `@hookform/resolvers` `yupResolver`.  */
        yup: typeof yupResolver;
    };
    /** A simple drop and replace of `react-hook-form`'s `FormProvider`.  */
    Provider: typeof FormProvider;
    /** A simple drop and replace of `react-hook-form`'s `useForm`.  */
    use: typeof useForm;
    useContext: typeof useFormContext;
}

const Form = forwardRef<HTMLFormElement, FormProps>(function Form(
    { children, className, onSubmit, disablePreventDefaultOnSubmit = false, ...rest },
    ref,
) {
    const handleOnSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            if (!disablePreventDefaultOnSubmit) {
                e.preventDefault();
            }
            onSubmit?.(e);
            return e.defaultPrevented;
        },
        [onSubmit, disablePreventDefaultOnSubmit],
    );

    return (
        <form {...rest} onSubmit={handleOnSubmit} ref={ref} className={className}>
            {children}
        </form>
    );
}) as FormNamespace;

Form.use = useForm;
Form.Provider = FormProvider;
Form.useContext = useFormContext;
Form.CreatableCombobox = FormCreatableCombobox;
Form.Select = FormSelect;
Form.MultiSelect = FormMultiSelect;
Form.ColorPicker = FormColorPicker;
Form.Input = FormInput;
Form.TextArea = FormTextArea;
Form.EditableText = FormEditableText;
Form.Checkbox = FormCheckbox;
Form.Switch = FormSwitch;
Form.RadioGroup = FormRadioGroup;
Form.Submit = FormSubmit;
Form.resolvers = {
    yup: yupResolver,
};

export type { FormProps };
export { Form };
