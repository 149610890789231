import { StylesConfig } from 'react-select';

import { tVariants } from '@blockworks/ui/system';

const creatableSelectStyles = tVariants({
    variants: {
        fullWidth: { true: 'w-full' },
        size: {
            sm: 'text-xs',
            md: 'text-sm',
            lg: 'text-md',
        },
    },
});

const controlStyles = tVariants({
    base: 'focus-within:!ring-primary-focus focus-within:!ring-1 hover:!border-surface-interact !cursor-text !text-xs',
});

const inputStyles = tVariants({
    base: 'focus:!ring-0 focus:!outline-none [&>input]:!outline-none [&>input]:!ring-0 !cursor-text',
});

const actionStyles = tVariants({
    base: 'cursor-pointer',
});

type ItemWithColor = {
    [key: string]: string;
    color: string;
};

const overrideStyles: StylesConfig<ItemWithColor> = {
    control: (styles, state) => ({
        ...styles,
        borderColor: state.isFocused ? 'border-surface-interact' : 'border-surface-divider',
        color: state.isFocused ? 'text-content' : 'text-content-muted',
    }),
    multiValue: (styles, state) => {
        const data = state.data as ItemWithColor;
        const hasColor = !!data.color;
        if (!hasColor) return styles;
        return {
            ...styles,
            backgroundColor: `#${data.color}30`,
        };
    },
    multiValueRemove: (styles, state) => {
        const data = state.data as ItemWithColor;
        const hasColor = !!data.color;
        if (!hasColor) return styles;
        return {
            ...styles,
            backgroundColor: `#${data.color}50`,
            color: 'white',
        };
    },
    option: (styles, state) => {
        const data = state.data as ItemWithColor;
        const hasColor = !!data.color;
        if (!hasColor) return styles;
        return {
            ...styles,
            fontSize: 12,
            cursor: 'pointer',
            pointerEvents: 'all',
            color: `#${data.color}90`,
        };
    },
};

export { actionStyles, controlStyles, creatableSelectStyles, inputStyles, overrideStyles };
