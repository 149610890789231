import { forwardRef } from 'react';
import { useController } from 'react-hook-form';

import { useMergeRefs } from '../../../hooks';
import { CombineRefWithProps } from '../../../models';
import { callAll } from '../../../utils';
import { Field } from '../field';
import type { FieldOrientationOptions } from '../field/context/field-context.model';
import { RadioGroup, RadioGroupProps } from '../radio-group';

import type { FieldValues, FormFieldWithControllerProps } from './form.model';

type FormRadioGroupProps<T extends FieldValues> = FormFieldWithControllerProps<T, RadioGroupProps, 'orientation'> &
    FieldOrientationOptions<false, true>;

export declare function FormRadioGroupComponent<T extends FieldValues>(
    props: CombineRefWithProps<HTMLButtonElement, FormRadioGroupProps<T>>,
): JSX.Element;

const FormRadioGroup = forwardRef<HTMLDivElement, FormRadioGroupProps<any>>(function FormRadioGroup(
    {
        name,
        control,

        options,
        errorMessage,
        hideErrorMessage = false,
        helpText,
        onChange,
        onValueChange,
        disabled,
        readOnly,
        required,
        defaultValue,
        label,
        id,
        orientation,
        size,
        ...rest
    },
    ref,
) {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required },
        disabled,
        defaultValue,
    });

    const internalRef = useMergeRefs<HTMLDivElement>(field.ref, ref);

    return (
        <Field
            invalid={!!error?.message}
            size={size}
            required={required}
            readOnly={readOnly}
            id={id}
            orientation={orientation}
        >
            <Field.Orientation>
                {label ? <Field.Label>{label}</Field.Label> : null}
                <RadioGroup
                    {...rest}
                    size={size}
                    ref={internalRef}
                    options={options}
                    value={field.value ?? ''}
                    defaultValue={defaultValue}
                    name={field.name}
                    disabled={field.disabled}
                    onValueChange={callAll(field.onChange, onValueChange)}
                />
            </Field.Orientation>
            {hideErrorMessage ? null : <Field.ErrorMessage>{errorMessage ?? error?.message}</Field.ErrorMessage>}
            {helpText ? <Field.HelpText>{helpText}</Field.HelpText> : null}
        </Field>
    );
}) as unknown as typeof FormRadioGroupComponent;

export { FormRadioGroup };
